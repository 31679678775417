import BasketLogo from '../assets/basket-logo.svg';
import BasketText from '../assets/basket-text.svg';
import React, { useState } from 'react';
import { functions, httpsCallable } from '../firebase/firebase';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const MigrationForm: React.FC = () => {
  const [username, setName] = useState('');
  const [email, setEmail] = useState('');
  const [confirmedEmail, setConfirmedEmail] = useState('');

  const [errorState, setErrorState] = useState<boolean | null>(null);
  const [errorStateText, setErrorStateText] = useState('');

  const query = useQuery();
  const userId = query.get('userId');

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const migrateAccount = httpsCallable(functions, 'account-transfer-request');
    try {
      if (email !== confirmedEmail) {
        setErrorState(true);
        setErrorStateText("Emails don't match!");
        return;
      }

      const response = await migrateAccount({ username, email, userId });

      setErrorState(false);
      // setErrorState(`Migration successful: ${response.data.message}`);
    } catch (error: any) {
      console.log(`${JSON.stringify(error.message)}`);
      console.log(error.code);

      setErrorState(true);

      if(error.code == "functions/already-exists") {
        setErrorStateText(`The user associated with this link has already submitted an account transfer request. If you've already filled out this form, then there's nothing more you need to do right now. If you need additional support, email hello@trybasket.com`);
      } else {

        setErrorStateText(
          `Your request could not be processed. Please try again or reach out to us at hello@trybasket.com`,
        );
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#F5F7F9] w-11/12 sm:w-full mx-auto">
      <div className="max-w-xl mx-auto bg-white rounded-[40px] shadow-lg p-12">
        <div className="text-center flex flex-row items-center justify-center mt-10 mb-5 gap-x-3">
          <img src={BasketLogo} alt="basket-logo" className="w-[50px] mr-2" />
          <img
            src={BasketText}
            alt="basket-text"
            className="w-[100px] md:w-[150px]"
          />
        </div>

        <div className="text-center">
          <h1 className="mb-5 font-medium sm:text-3xl text-2xl">Migrate your account</h1>
          <p className="text-gray-400 sm:mx-8 mb-5 sm:mb-0">
          From 12 August, you'll no longer be able to log into Basket using Facebook. Enter your email below so that we can migrate your account.
          </p>
        </div>
        <div className="sm:m-7">
          <form onSubmit={handleSubmit} id="form">
            <div className="mb-6">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email address*"
                required
                className="w-full px-[10px] py-[16px] bg-[rgba(18,41,104,0.04)] rounded-md rounded-[10px] focus:outline-none focus:ring focus:ring-indigo-100"
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                  if (!emailRegex.test(e.target.value)) {
                    setErrorState(true);
                    setErrorStateText('Email is invalid!');
                  } else {
                    setErrorState(null);
                  }
                }}
              />
            </div>
            <div className="mb-6">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Confirm email address*"
                required
                className="w-full px-[10px] py-[16px] bg-[rgba(18,41,104,0.04)] rounded-md rounded-[10px] focus:outline-none focus:ring focus:ring-indigo-100"
                value={confirmedEmail}
                onChange={e => {
                  setConfirmedEmail(e.target.value);
                  if (email !== e.target.value) {
                    setErrorState(true);
                    setErrorStateText("Emails don't match!");
                  } else {
                    setErrorState(null);
                  }
                }}
              />
            </div>

            <div className="mb-6">
              <button
                type="submit"
                className="w-full px-5 py-3 text-white bg-[#448CF0] rounded-3xl font-bold"
              >
                Submit request
              </button>
            </div>

            {/* Error state */}
            {errorState === true && (
              <div
                className="flex bg-red-100 rounded-2xl p-4 mb-4 text-sm text-red-700"
                role="alert"
              >
                <div>{errorStateText}</div>
              </div>
            )}
            {errorState === false && (
              <div
                className="flex bg-green-100 rounded-xl p-4 mb-4 text-sm text-green-700"
                role="alert"
              >
                <div>
                Thanks for submitting your details. We'll send you an email soon with a link to create a password. Until then you can continue to use Facebook to log in. 
                </div>
              </div>
            )}
            {/* {errorState !== null && (
              <></>
            )} */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default MigrationForm;
